import { action, computed, observable } from 'mobx';
import { Animal } from '../domain/Animal';
import { AnimalGroupStore } from './AnimalGroupStore';

enum AnimalStoreStatus {
	InitialState = 0,
	Found = 1,
	NotFound = 2,
	Loading = 3,
}

export class AnimalStore {
	@observable animal?: Animal = undefined;
	@observable private storeStatus = AnimalStoreStatus.InitialState;

	constructor(private animalGroupStore: AnimalGroupStore) {}

	@computed get showCloseButton() {
		return this.storeStatus === AnimalStoreStatus.Found;
	}

	@computed get animalNotFound() {
		return this.storeStatus === AnimalStoreStatus.NotFound;
	}

	@computed get searchIsInProgress() {
		return this.storeStatus === AnimalStoreStatus.Loading;
	}

	@action reset(): void {
		this.animal = undefined;
		this.storeStatus = AnimalStoreStatus.InitialState;
	}

	@action findAnimal(managementNumber: number): Promise<void> {
		if (!this.animalGroupStore.animalGroup) {
			console.log('Cannot find animal when there is no group selected');
			return Promise.resolve();
		}

		this.animal = undefined;
		this.storeStatus = AnimalStoreStatus.Loading;

		let foundAnimal: Animal | undefined;

		return this.animalGroupStore.animalGroup
			.findGroupAnimal(managementNumber)
			.then(animal => {
				if (!animal) {
					return Promise.resolve();
				}
				foundAnimal = animal;
				return foundAnimal.loadAllAnimalEvents();
			})
			.then(() => (foundAnimal ? this.handleAnimalFound(foundAnimal) : this.handleAnimalNotFound()))
			.catch(error => this.handledError(error));
	}

	@action private handleAnimalFound(animal: Animal) {
		this.animal = animal;
		this.storeStatus = AnimalStoreStatus.Found;
	}

	@action private handleAnimalNotFound() {
		this.animal = undefined;
		this.storeStatus = AnimalStoreStatus.NotFound;
	}

	@action private handledError(error: Error) {
		this.handleAnimalNotFound();
		console.log(error);
	}
}
