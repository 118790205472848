import { AppConfig, getAppConfiguration } from './services/context/AppConfig';
import { makeRootStore, RootStore } from './services/stores/RootStore';
import { FeedItem } from './services/representations/Representation';
import { makeRestClient, RestClient } from './services/rest/RestClient';
import { makeFetchHttpClient } from './services/rest/FetchHttpClient';
import { AuthOptions } from './services/rest/HttpAuthenticationUtils';
import { i18n, TranslateFunction } from './services/i18n';

interface AppUserData {
	selectedGroupUrl?: string;
	userToken?: string;
}

export interface AppState {
	appConfig?: AppConfig;
	userData?: AppUserData;
	rootStore?: RootStore;
	groupFound: boolean;
}

const LOCAL_STORAGE_ANIMAL_GROUP_BP = 'animalGroupBpNumber';

export async function buildAppInitialState(): Promise<AppState> {
	try {
		// Login redirects lose the selectedHerd query param, save in local storage.
		const animalGroupBpNumberParameter = new URLSearchParams(window.location.search).get('selectedHerd');

		// The selectedHerd may not always be passed as a query parameter, so use
		// the last herd written to local storage.
		const animalGroupBpNumber = animalGroupBpNumberParameter
			? animalGroupBpNumberParameter
			: window.localStorage.getItem(LOCAL_STORAGE_ANIMAL_GROUP_BP);

		if (!animalGroupBpNumber) {
			throw new Error('There is no BP number selected');
		}

		window.localStorage.setItem(LOCAL_STORAGE_ANIMAL_GROUP_BP, animalGroupBpNumber!);

		let userToken: string | undefined;
		const appConfig = await getAppConfiguration();

		try {
			userToken = await getUserToken(appConfig.UI_PROXY_ENDPOINT);
		} catch (error) {
			// Problem accessing token, this may happen when running locally with a header modifier.
		}

		let foundGroup: FeedItem;

		const restClient = setupRestClient(appConfig, userToken);
		return restClient
			.getFeed(appConfig.ANIMAL_GROUP_API_URL)
			.then(accessibleAnimalGroups => accessibleAnimalGroups || [])
			.then(accessibleAnimalGroups => findGroup(animalGroupBpNumber!, accessibleAnimalGroups.items))
			.then(animalGroup => (foundGroup = animalGroup))
			.then(() => i18n)
			.then((translate: TranslateFunction) => setupRootStore(foundGroup.id, translate, restClient, appConfig))
			.then(rootStore => makeState(appConfig, foundGroup, userToken, rootStore))
			.catch(error => {
				throw error;
			});
	} catch (error) {
		console.error(error.message);
		// Could not convert the BP number into an animal animalGroup,
		// not the end of the world, the page can still technically do things.
		return Promise.resolve({
			userData: {},
			groupFound: false,
		});
	}
}

function getUserToken(uiProxyEndpoint: string) {
	return fetch(`${uiProxyEndpoint}/token`, { credentials: 'include' })
		.then(response => {
			if (!response.ok) {
				throw new Error(`Cannot fetch ${response.status}`);
			}

			return response;
		})
		.then(response => response.json())
		.then((body: { token?: string }) => body.token);
}

function findGroup(animalGroupBpNumber: string, accessibleAnimalGroups: Array<FeedItem>): FeedItem {
	const currentlySelectedGroup = accessibleAnimalGroups.find(group => group.title === animalGroupBpNumber);
	if (!currentlySelectedGroup) {
		throw new Error(`No group was found with BP number ${animalGroupBpNumber}`);
	}
	return currentlySelectedGroup!;
}

function setupRestClient(config: AppConfig, userToken?: string): RestClient {
	const httpClient = makeFetchHttpClient();

	const onAuthFailed: (authOptions: AuthOptions) => Promise<boolean> = () => {
		window.location.href = `${config.UI_PROXY_ENDPOINT}${config.UI_PROXY_CONNECT_PATH}`;
		return Promise.reject(new Error('authentication failed'));
	};

	return makeRestClient(httpClient, {
		bearerToken: userToken,
		onAuthenticationFailed: onAuthFailed,
	});
}

function setupRootStore(
	groupUri: string,
	translate: TranslateFunction,
	restClient: RestClient,
	appConfig: AppConfig
): Promise<RootStore> {
	const rootStore = makeRootStore(restClient, translate, appConfig);
	return rootStore.animalGroupStore.loadGroup(groupUri).then(() => rootStore);
}

function makeState(
	appConfig: AppConfig,
	selectedGroup: FeedItem,
	userToken: string | undefined,
	rootStore: RootStore
): AppState {
	return {
		appConfig,
		userData: { selectedGroupUrl: selectedGroup.id, userToken },
		rootStore,
		groupFound: true,
	};
}
