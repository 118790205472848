import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AnimalEvent } from '../../../../../../../../services/domain/AnimalEventCommon';
import { RootStore } from '../../../../../../../../services/stores/RootStore';
import { bindToMobx } from '../../../../../../../../services/context/bindToMobx';
import { AnimalEventMarker } from './components/AnimalEventMarker/AnimalEventMarker';
import { EventStream } from './components/EventStream/EventStream';

const renderAnimalEvent = (
	event: AnimalEvent,
	translateNamespace: string,
	onEventSelectionChanged: (isSelected: boolean) => void
) => (
	<AnimalEventMarker
		key={event.id}
		translateNamespace={translateNamespace}
		animalEvent={event}
		onEventSelectionChanged={onEventSelectionChanged}
	/>
);

interface SwimlaneProps {
	streamName: string;
	events: Array<AnimalEvent>;
}

interface SwimlaneState {
	hasEventSelected: boolean;
}

interface SwimlaneStoreProps {
	t: (key: string) => string;
}

interface SwimlaneComponentProps extends SwimlaneProps, SwimlaneStoreProps {}

@observer
export class SwimlaneComponent extends Component<SwimlaneComponentProps, SwimlaneState> {
	constructor(props: SwimlaneComponentProps) {
		super(props);
		this.state = { hasEventSelected: false };
	}

	onEventSelectionChanged = (isSelected: boolean) => {
		this.setState({ hasEventSelected: isSelected });
	};

	render() {
		const { t, streamName, events } = this.props;

		return (
			<EventStream name={t(`${streamName}.title`)} hasEventSelected={this.state.hasEventSelected}>
				{events.map(event => renderAnimalEvent(event, streamName, this.onEventSelectionChanged))}
			</EventStream>
		);
	}
}

function getProps(rootStore: RootStore): SwimlaneStoreProps {
	return {
		t: rootStore.uiStore.translate,
	};
}

export const Swimlane = bindToMobx<SwimlaneProps>(getProps, SwimlaneComponent);
