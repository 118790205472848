import React, { Component } from 'react';
import { AnimalStore } from '../../../../../../../../services/stores/AnimalStore';
import { bindToMobx } from '../../../../../../../../services/context/bindToMobx';
import { RootStore } from '../../../../../../../../services/stores/RootStore';
import { UiStore } from '../../../../../../../../services/stores/UiStore';
import styles from './AnimalLookup.module.scss';
import { NotFound } from './components/NotFound/NotFound';
import { closeButton } from './images';

interface AnimalLookupProps {
	className: string;
}

interface StoreProps {
	animalStore: AnimalStore;
	uiStore: UiStore;
}

interface AnimalLookupComponentProps extends StoreProps, AnimalLookupProps {}

interface AnimalLookupComponentState {
	text: string;
}

export class AnimalLookupComponent extends Component<AnimalLookupComponentProps, AnimalLookupComponentState> {
	constructor(props: AnimalLookupComponentProps) {
		super(props);
		this.state = { text: '' };
	}

	findAnimal(): void {
		const managementNumber = Number(this.state.text);
		this.props.uiStore.setDefaultTimelineEventsDateRange();
		this.props.animalStore.findAnimal(managementNumber);
	}

	render() {
		const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.props.animalStore.reset();
			this.setState({ text: event.target.value });
			this.props.uiStore.resetTimelineVisible();
		};

		const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				this.findAnimal();
			}
		};

		const handleButtonClick = () => {
			this.findAnimal();
		};

		const handleImageClick = () => {
			this.setState({ text: '' });
			this.props.animalStore.reset();
			this.props.uiStore.resetTimelineVisible();
		};

		return (
			<div className={`${this.props.className} ${styles.main}`}>
				<input
					disabled={this.props.animalStore.searchIsInProgress}
					type="number"
					placeholder="Mgmt no."
					onChange={handleTextChange}
					onKeyUp={handleKeyUp}
					value={this.state.text}
				/>
				<button
					disabled={this.props.animalStore.searchIsInProgress}
					className={styles.lookupButton}
					onClick={handleButtonClick}
				>
					{this.props.animalStore.searchIsInProgress ? 'Searching...' : 'Lookup'}
				</button>
				{this.props.animalStore.showCloseButton && (
					<img alt="Close" src={closeButton} className={styles.closeButton} onClick={handleImageClick} />
				)}
				{this.props.animalStore.animalNotFound && <NotFound />}
			</div>
		);
	}
}

const getProps = (rootStore: RootStore): StoreProps => ({
	animalStore: rootStore.animalStore,
	uiStore: rootStore.uiStore,
});

export const AnimalLookup = bindToMobx<AnimalLookupProps>(getProps, AnimalLookupComponent);
