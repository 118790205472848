import { FeedRepresentation, LinkedRepresentation } from '../representations/Representation';
import { RestHttpResponse } from './RestHttpClient';

export class HttpResponseError extends Error {
	responseStatus: number;
	requestUri: string;
	requestVerb: string;

	constructor(responseStatus: number, requestUri: string, requestVerb: string) {
		super(`${requestVerb} '${requestUri}' returned status code: ${responseStatus}`);

		this.responseStatus = responseStatus;
		this.requestUri = requestUri;
		this.requestVerb = requestVerb;
	}
}

export function findLocationHeader(response: RestHttpResponse): string {
	const location = response.headers.find(header => header.key.toLowerCase() === 'location');
	if (!location) {
		throw new Error(`${response.requestVerb} '${response.requestUri}' response doesnt have a location header`);
	}

	return location.value;
}

export function validateStatus(response: RestHttpResponse): RestHttpResponse {
	if (response.status < 400) {
		return response;
	} else {
		throw new HttpResponseError(response.status, response.requestUri, response.requestVerb);
	}
}

export function validateBody(response: RestHttpResponse): RestHttpResponse {
	if (!response.bodyText || response.bodyText.trim().length === 0) {
		throw new Error(`${response.requestVerb} '${response.requestUri}' response doesnt have any content`);
	}

	return response;
}

export function makeLinkedRepresentation(response: RestHttpResponse): LinkedRepresentation {
	const representation = parseJsonResponseRepresentation(response);
	if (!representation.links || representation.links.length === 0) {
		throw new Error(`${response.requestVerb} '${response.requestUri}' response has no links`);
	}

	return representation;
}

export function makeFeedRepresentation(response: RestHttpResponse): FeedRepresentation {
	const feedRepresentation = makeLinkedRepresentation(response) as FeedRepresentation;
	if (!feedRepresentation.items) {
		throw new Error(`${response.requestVerb} '${response.requestUri}' response items property is not defined`);
	}

	return feedRepresentation;
}

function parseJsonResponseRepresentation(response: RestHttpResponse): LinkedRepresentation {
	try {
		return JSON.parse(response.bodyText) as LinkedRepresentation;
	} catch {
		throw new Error(`${response.requestVerb} '${response.requestUri}' response json could not be parsed`);
	}
}
