import { computed } from 'mobx';
import { InMilkEventRepresentation } from '../representations/InMilkEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class InMilkEvent extends AnimalEventCommon<InMilkEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
		};
	}

	isDryOff(): boolean {
		return this.representation.isStart === false && this.representation.isLactationEvent === true;
	}
}
