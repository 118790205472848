import { differenceInCalendarDays } from 'date-fns';
import { AnimalEvent } from '../../../../../../../services/domain/AnimalEventCommon';
import {
	dateOfOnFarmEventAscending,
	eventWithinRange,
	setPeriodOffset,
} from '../../../../../../../services/domain/animalEventUtilities';
import { DateRange } from '../../../../../../../services/stores/UiStore';

export function prepareEventsForTimeline(events: Array<AnimalEvent>, { from, to }: DateRange): Array<AnimalEvent> {
	const daysInPeriod = differenceInCalendarDays(from, to);
	return events
		.sort(dateOfOnFarmEventAscending)
		.filter(eventWithinRange(from, to))
		.map(setPeriodOffset(from, daysInPeriod));
}
