import { computed, observable } from 'mobx';
import { DDMMMMYYYY } from '../formatters/formatters';
import { AnimalBirthId, AnimalRepresentation } from '../representations/AnimalRepresentation';
import { findAnimalCurrentGroupMembership } from '../representations/GroupMembershipRepresentation';
import { AnimalBreed } from './AnimalBreed';
// eslint-disable-next-line import/no-cycle
import { CalvingEvent } from './CalvingEvent';
import { decorateAllAsObservable } from './decorateAllAsObservable';
import { HealthConditionEvent } from './HealthConditionEvent';
import { MatingEvent } from './MatingEvent';
import { PregnancyDiagnosisEvent } from './PregnancyDiagnosisEvent';
import { HeatEvent } from './HeatEvent';
import { LiveWeightEvent } from './LiveWeightEvent';
import { InMilkEvent } from './InMilkEvent';
import { ManagementNumberEvent } from './ManagementNumberEvent';

export class Animal {
	readonly id: string;
	@observable private representation: AnimalRepresentation;
	constructor(representation: AnimalRepresentation) {
		this.representation = decorateAllAsObservable(representation);
		this.id = representation.links.find(x => x.rel === 'self')!.href;
	}

	@computed get birthDate() {
		return this.representation ? DDMMMMYYYY(this.representation.birthDate!) : null;
	}

	@computed get eid() {
		if (this.representation) {
			const id = this.representation.identifiers.find(id => id.includes('electronic'));

			if (id) {
				return formatEid(id);
			}
		}

		return null;
	}

	@computed get managementNumber() {
		if (this.representation) {
			const id = this.representation.identifiers.find(id => id.includes('management'));

			if (id) {
				return formatManagementNumber(id);
			}
		}

		return null;
	}

	@computed get birthIdentifier() {
		if (this.representation && this.representation.birthId) {
			return birthIdentifier(this.representation.birthId);
		}

		return null;
	}

	@computed get breedIdentifier() {
		return this.representation ? formatBreeds(this.representation.breeds) : null;
	}

	@computed get sex() {
		return this.representation ? this.representation.sex : null;
	}

	@computed get enteredHerd() {
		return this.representation.groupMemberships.length > 0
			? DDMMMMYYYY(findAnimalCurrentGroupMembership(this.representation.groupMemberships).startDate)
			: null;
	}

	@computed get calvingEvents(): Array<CalvingEvent> {
		return this.representation.calvingEvents.map(x => new CalvingEvent(x));
	}

	@computed get matingEvents(): Array<MatingEvent> {
		return this.representation.matingEvents.map(x => new MatingEvent(x));
	}

	@computed get pregnancyDiagnosisEvents(): Array<PregnancyDiagnosisEvent> {
		return this.representation.pregnancyDiagnosisEvents.map(x => new PregnancyDiagnosisEvent(x));
	}

	@computed get heatEvents(): Array<HeatEvent> {
		return this.representation.heatEvents.map(x => new HeatEvent(x));
	}

	@computed get healthConditionEvents(): Array<HealthConditionEvent> {
		return this.representation.healthConditionEvents.map(x => new HealthConditionEvent(x));
	}

	@computed get liveWeights(): Array<LiveWeightEvent> {
		return this.representation.liveWeightEvents.map(x => new LiveWeightEvent(x));
	}

	@computed get inMilkEvents(): Array<InMilkEvent> {
		return this.representation.inMilkEvents.map(x => new InMilkEvent(x)).filter(x => x.isDryOff());
	}

	@computed get managementNumberEvents(): Array<ManagementNumberEvent> {
		return this.representation.managementNumberEvents
			.map(x => new ManagementNumberEvent(x))
			.filter(x => x.isStart());
	}

	loadAllAnimalEvents(): Promise<void> {
		return this.representation.hydrate();
	}
}

export function formatBreeds(breeds: Array<AnimalBreed>): string {
	return breeds.reduce(formatBreedIdentifier, '').trim();
}

function formatBreedIdentifier(id: string, breed: AnimalBreed): string {
	const { abbreviation, portion16th } = breed;
	return `${id} ${abbreviation}${portion16th}`;
}

export function birthIdentifier(birthId: AnimalBirthId): string {
	const { prefix, sequence, year } = birthId;
	const lastTwoDigits = year % 100;
	const birthYear = lastTwoDigits.toString().padStart(2, '0');
	return `${prefix}-${birthYear}-${sequence}`;
}

export function formatManagementNumber(managementNumber: string) {
	return managementNumber.split(':').pop();
}

export function formatEid(eid: string): string {
	return eid
		.split(':')
		.pop()!
		.replace('_', ' ');
}
