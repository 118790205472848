import { computed } from 'mobx';
import { CalvingEventRepresentation } from '../representations/CalvingEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';
// eslint-disable-next-line import/no-cycle
import { birthIdentifier } from './Animal';

export class CalvingEvent extends AnimalEventCommon<CalvingEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			calfSex: this.representation.calfSex,
			calfFate: this.representation.calfFate,
			calfBirthId:
				this.representation.calf && this.representation.calf.birthId
					? birthIdentifier(this.representation.calf.birthId)
					: undefined,
		};
	}
}
