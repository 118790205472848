import React, { Component } from 'react';
import { bindToMobx } from '../../../../../../../../../../services/context/bindToMobx';
import { RootStore } from '../../../../../../../../../../services/stores/RootStore';
import styles from './NextPreviousVisiblePeriod.module.scss';

interface NextPreviousVisiblePeriodProps {
	setPrevious: () => void;
	setNext: () => void;
	setCurrentYear: () => void;
}

export class NextPreviousVisiblePeriodComponent extends Component<NextPreviousVisiblePeriodProps> {
	render(): JSX.Element {
		return (
			<div className={styles.main}>
				<div className={styles.translucent} />
				<button className={styles.leftArrow} onClick={this.props.setPrevious} />
				<button className={styles.current} onClick={this.props.setCurrentYear} />
				<button className={styles.rightArrow} onClick={this.props.setNext} />
			</div>
		);
	}
}

export function getProps(rootStore: RootStore): NextPreviousVisiblePeriodProps {
	return {
		setNext: () => rootStore.uiStore.setNextTimelineEventsDateRange(),
		setPrevious: () => rootStore.uiStore.setPreviousTimelineEventsDateRange(),
		setCurrentYear: () => rootStore.uiStore.setDefaultTimelineEventsDateRange(),
	};
}

export const NextPreviousVisiblePeriod = bindToMobx(getProps, NextPreviousVisiblePeriodComponent);
