import { LinkedRepresentation } from './Representation';
import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';

export interface MatingEventRepresentation extends AnimalEventRepresentation {
	coreProductCode: string;
	mateChargeType: string;
	matingType: string;
}

export function toMatingEvents(linkedRepresentations: Array<LinkedRepresentation>): Array<MatingEventRepresentation> {
	return linkedRepresentations.map(toMatingEvent);
}

export function toMatingEvent(representation: LinkedRepresentation): MatingEventRepresentation {
	return toAnimalEvent(representation, 'matingEvent');
}
