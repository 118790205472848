import React, { Component } from 'react';
import { bindToMobx } from '../../../../../../services/context/bindToMobx';
import { Animal } from '../../../../../../services/domain/Animal';
import { RootStore } from '../../../../../../services/stores/RootStore';
import { UiStore } from '../../../../../../services/stores/UiStore';
import styles from './AnimalTimeline.module.scss';
import { Swimlane } from './components/Swimlane/Swimlane';
import { VisiblePeriodControl } from './components/VisiblePeriodControl/VisiblePeriodControl';
import { prepareEventsForTimeline } from './services/TimelineEventFilters';

interface AnimalTimelineProps {
	animal: Animal;
	uiStore: UiStore;
}

export class AnimalTimelineComponent extends Component<AnimalTimelineProps, {}> {
	private outerBoundaryContainer: any;

	constructor(props: AnimalTimelineProps) {
		super(props);
		this.outerBoundaryContainer = undefined;
	}

	componentDidMount() {
		// This code is added so we can auto-magically scroll to the right most of the div
		// we have decided for the first cut we would have a fixed width with a scroll bar
		// the intention is that we will be removing this after customer feedback
		if (this.outerBoundaryContainer) {
			this.outerBoundaryContainer.scrollTo({ left: window.innerWidth });
		}
	}

	render() {
		const {
			uiStore: { timelineEventsDateRange, timelineVisible },
		} = this.props;

		const animal = this.props.animal;

		if (!timelineVisible || !animal) {
			return null;
		}

		const dateRange = timelineEventsDateRange;

		return (
			<div className={styles.outerBoundary} ref={ref => (this.outerBoundaryContainer = ref)}>
				<div className={styles.main}>
					<Swimlane
						streamName={'modals.health'}
						events={prepareEventsForTimeline(animal ? animal.healthConditionEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.liveweight'}
						events={prepareEventsForTimeline(animal ? animal.liveWeights : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.calving'}
						events={prepareEventsForTimeline(animal ? animal.calvingEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.mating'}
						events={prepareEventsForTimeline(animal ? animal.matingEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.heat'}
						events={prepareEventsForTimeline(animal ? animal.heatEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.pregnancy'}
						events={prepareEventsForTimeline(animal ? animal.pregnancyDiagnosisEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.inmilk'}
						events={prepareEventsForTimeline(animal ? animal.inMilkEvents : [], dateRange)}
					/>
					<Swimlane
						streamName={'modals.managementnumber'}
						events={prepareEventsForTimeline(animal ? animal.managementNumberEvents : [], dateRange)}
					/>
					<VisiblePeriodControl {...dateRange} />
				</div>
			</div>
		);
	}
}

function getProps(rootStore: RootStore): AnimalTimelineProps {
	return {
		animal: rootStore.animalStore.animal!,
		uiStore: rootStore.uiStore,
	};
}

export const AnimalTimeline = bindToMobx(getProps, AnimalTimelineComponent);
