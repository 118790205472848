import React, { FunctionComponent } from 'react';
import styles from './AnimalDetailItem.module.scss';

interface AnimalDetailItemProps {
	label: string;
	value: string | undefined | null;
}

export const AnimalDetailItem: FunctionComponent<AnimalDetailItemProps> = ({ label, value }) => (
	<div className={styles.main}>
		<span className={styles.label}>{label}:</span> <span className={styles.value}>{value}</span>
	</div>
);
