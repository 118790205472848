import React from 'react';
import { UserData } from '../../services/context/UserDataContext';
import { AnimalProfile } from './components/AnimalProfile/AnimalProfile';
import MindaSwitch from './components/MindaSwitch/MindaSwitch';

import './LandingPage.css';
import NoSelectedHerd from './components/NoSelectedHerd/NoSelectedHerd';
import { LandingPageContainer } from './LandingPageContainer';

const LandingPage = (props: { userData: UserData }) => {
	return (
		<div className="landing-container">
			<AnimalProfile />
			<main>
				{props.userData.selectedGroupUrl ? (
					<LandingPageContainer
						userToken={props.userData.userToken}
						selectedGroupUrl={props.userData.selectedGroupUrl}
					/>
				) : (
					<NoSelectedHerd />
				)}
			</main>

			<footer>
				<MindaSwitch />
				<span className="copyright">&copy; 2019 Copyright LIC - All rights reserved</span>
			</footer>
		</div>
	);
};

export default LandingPage;
