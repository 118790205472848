import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';
import { LinkedRepresentation } from './Representation';

export interface InMilkEventRepresentation extends AnimalEventRepresentation {
	isStart: boolean;
	isLactationEvent: boolean;
}

export function toInMilkEvents(linkedRepresentations: Array<LinkedRepresentation>): Array<InMilkEventRepresentation> {
	return linkedRepresentations.map(toInMilkEvent);
}

export function toInMilkEvent(representation: LinkedRepresentation): InMilkEventRepresentation {
	const animalEvent = toAnimalEvent<InMilkEventRepresentation>(representation, 'inMilkEvent');
	animalEvent.isStart = representation.isStart;
	animalEvent.isLactationEvent = representation.isLactationEvent;
	return animalEvent;
}
