import { LinkedRepresentation } from './Representation';
import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';

export interface PregnancyDiagnosisEventRepresentation extends AnimalEventRepresentation {
	daysPregnant: number;
	history: string;
	pregnancyStatus: string;
}

export function toPregnancyDiagnosisEvents(
	linkedRepresentations: Array<LinkedRepresentation>
): Array<PregnancyDiagnosisEventRepresentation> {
	return linkedRepresentations.map(toPregnancyDiagnosisEvent);
}

export function toPregnancyDiagnosisEvent(representation: LinkedRepresentation): PregnancyDiagnosisEventRepresentation {
	return toAnimalEvent(representation, 'pregnancyDiagnosisEvent');
}
