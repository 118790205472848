import React, { FunctionComponent } from 'react';
import { RootStore } from '../../../../../../../../../../services/stores/RootStore';
import { bindToMobx } from '../../../../../../../../../../services/context/bindToMobx';
import { AnimalEvent } from '../../../../../../../../../../services/domain/AnimalEventCommon';
import { TranslateFunction } from '../../../../../../../../../../services/i18n';
import { calendar, closeButton } from './images/index';
import styles from './AnimalEventModal.module.scss';

interface AnimalEventModalProps {
	close: () => void;
	animalEvent: AnimalEvent;
	translateNamespace: string;
}

interface AnimalEventModalStoreProps {
	t: (key: string) => string;
}

interface AnimalEventModalComponentProps extends AnimalEventModalProps, AnimalEventModalStoreProps {}

export const AnimalEventModalComponent: FunctionComponent<AnimalEventModalComponentProps> = ({
	close,
	animalEvent,
	translateNamespace,
	t,
}) => {
	const { occurred, ...displayProps } = animalEvent.displayProperties;

	const eventFields = Object.keys(displayProps);
	const translateFields = translateField(t, translateNamespace, displayProps);

	return (
		<div className={styles.main}>
			<img src={closeButton} className={styles.closeButton} onClick={close} alt="Close" />
			<h3>{t(`${translateNamespace}.title`)}</h3>
			<div className={styles.calendar}>
				<img src={calendar} className={styles.icon} alt="Calendar" />
				<div className={styles.occurred}>
					<h6 className={styles.occurredLabel}>{t('modals.common.dateOfOnFarmEvent')}</h6>
					<p className={styles.occurredDate}>{occurred}</p>
				</div>
			</div>

			{eventFields.length > 0 && <h4>{t('modals.common.detail')}</h4>}
			{eventFields.map(prop => {
				const data = translateFields(prop);
				return (
					<div key={prop} className={styles.modalDetailItem}>
						<span className={styles.detailItemLabel}>{data.label}</span>
						<span className={styles.detailItemValue}>{data.value}</span>
					</div>
				);
			})}
		</div>
	);
};

function translateField(t: TranslateFunction, namespace: string, source: any) {
	return (prop: string) => {
		const field = {
			label: t(`${namespace}.${prop}.label`),
			value: t(`${namespace}.${prop}.values.${source[prop]}`),
		};

		if (source[prop] === undefined) {
			field.value = t('modals.common.valueNotSet');
		}

		if (field.value === `${namespace}.${prop}.values.${source[prop]}`) {
			field.value = source[prop];
		}
		return field;
	};
}

const getProps = (rootStore: RootStore): AnimalEventModalStoreProps => ({
	t: rootStore.uiStore.translate,
});

export const AnimalEventModal = bindToMobx<AnimalEventModalProps>(getProps, AnimalEventModalComponent);
