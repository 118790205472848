import { action, computed, observable } from 'mobx';
import { DDMMMMYYYY } from '../formatters/formatters';
import { AnimalEventRepresentation } from '../representations/AnimalEventRepresentation';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export interface AnimalEvent {
	readonly id: string;
	dateOfOnFarmEvent?: Date;
	readonly displayProperties: any;
	periodOffset?: number;

	setPeriodOffset(offset: number): void;
}

export abstract class AnimalEventCommon<T extends AnimalEventRepresentation> implements AnimalEvent {
	readonly id: string;
	@observable periodOffset?: number;
	protected representation: T;

	constructor(representation: AnimalEventRepresentation) {
		this.id = representation.links.find(x => x.rel === 'self')!.href;

		// TODO: check why the line below is causing a recursive loop error in mobex. This happens with Calving event and Health Condition event only.
		// this.representation = (decorateAllAsObservable(representation) as any) as T;

		this.representation = representation as T;
	}

	@computed get dateOfOnFarmEvent(): Date {
		return this.representation.dateOfOnFarmEvent;
	}

	@computed get dateOfOnFarmEventFormatted(): string {
		return DDMMMMYYYY(this.representation.dateOfOnFarmEvent);
	}

	abstract get displayProperties(): DialogueDisplayProps;

	@action setPeriodOffset(offset: number): void {
		this.periodOffset = offset;
	}
}
