import { formatToTimeZone } from 'date-fns-timezone';
import { pacificAuckland } from './timezones';

export function DDMMMMYYYY(date: Date, timezoneString: string = pacificAuckland): string {
	return formatToTimeZone(date, 'DD MMMM YYYY', { timeZone: timezoneString });
}

export function MonthAndYear(date: Date, timezoneString: string = pacificAuckland): string {
	return formatToTimeZone(date, 'MMM-YY', { timeZone: timezoneString });
}

export const YesNo = (value: boolean): string => (value ? 'Yes' : 'No');

export function ifDefined<T, TResult>(
	possiblyDefined: T | undefined,
	callbackIfDefined: (source: T) => TResult
): TResult | undefined {
	return possiblyDefined ? callbackIfDefined(possiblyDefined!) : undefined;
}
