import React, { FunctionComponent } from 'react';
import { Navbar, Image } from 'rbx';
import { RootStore } from '../../../../../../../../services/stores/RootStore';
import { bindToMobx } from '../../../../../../../../services/context/bindToMobx';
import { ProfileIcon, MindaLiveLogoBlue } from '../../../../../../../../assets/images';
import styles from './MainNavbar.module.scss';

interface StoreProps {
	logoutUrl: string;
	participantCode: string;
}

export const MainNavbarComponent: FunctionComponent<StoreProps> = props => {
	return (
		<Navbar className="nav-box">
			<Navbar.Brand>
				<Navbar.Item>
					<img src={MindaLiveLogoBlue} alt="MindaLiveLogoBlue" />
				</Navbar.Item>
				<Navbar.Burger />
			</Navbar.Brand>
			<Navbar.Menu>
				<Navbar.Segment align="end">
					<Navbar.Item as="span">{props.participantCode}</Navbar.Item>
					<Navbar.Item dropdown hoverable>
						<Navbar.Link>
							<span className={styles.userName}>User</span>
							<Image.Container size={24}>
								<Image src={ProfileIcon} rounded />
							</Image.Container>
						</Navbar.Link>
						<Navbar.Dropdown boxed align="right">
							<Navbar.Item href={props.logoutUrl}>Logout</Navbar.Item>
						</Navbar.Dropdown>
					</Navbar.Item>
				</Navbar.Segment>
			</Navbar.Menu>
		</Navbar>
	);
};

function getProps(rootStore: RootStore): StoreProps {
	const props = {
		logoutUrl: rootStore.appConfig.LOGOUT_URL,
		participantCode: '',
	};

	if (rootStore.animalGroupStore.animalGroup) {
		props.participantCode = rootStore.animalGroupStore.animalGroup!.participantCode;
	}
	return props;
}

export const MainNavbar = bindToMobx(getProps, MainNavbarComponent);
