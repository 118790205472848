import { LinkedRepresentation } from './Representation';
import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';

export interface LiveWeightEventRepresentation extends AnimalEventRepresentation {
	weight: number;
}

export function toLiveWeightEvents(
	linkedRepresentations: Array<LinkedRepresentation>
): Array<LiveWeightEventRepresentation> {
	return linkedRepresentations.map(toLiveWeightEvent);
}

function toLiveWeightEvent(representation: LinkedRepresentation): LiveWeightEventRepresentation {
	const event = toAnimalEvent(representation, 'liveWeightEvent');
	const liveWeightEvent = event as LiveWeightEventRepresentation;
	if (liveWeightEvent.weight) {
		liveWeightEvent.weight = Number(representation.weight);
	}
	return liveWeightEvent;
}
