import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './EventStream.module.scss';

interface EventStreamProps {
	name: string;
	hasEventSelected: boolean;
}

export const EventStream: FunctionComponent<EventStreamProps> = ({ children, name, hasEventSelected }) => (
	<div className={classNames(styles.main, hasEventSelected ? styles.selected : '')}>
		<div className={styles.name}>{name}</div>
		<div className={styles.eventContainer}>{children}</div>
	</div>
);
