import { LinkedRepresentation } from './Representation';
import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';

export interface HeatEventRepresentation extends AnimalEventRepresentation {}

export function toHeatEvents(linkedRepresentations: Array<LinkedRepresentation>): Array<HeatEventRepresentation> {
	return linkedRepresentations.map(toHeatEvent);
}

export function toHeatEvent(representation: LinkedRepresentation): HeatEventRepresentation {
	return toAnimalEvent(representation, 'heatEvent');
}
