import React from 'react';
import { AppConfigContext } from '../../../../services/context/AppConfigContext';
import './NoSelectedHerd.css';

class NoSelectedHerd extends React.Component<{}, {}> {
	public static contextType = AppConfigContext;
	public context!: React.ContextType<typeof AppConfigContext>;

	public render() {
		return (
			<div className="no-selected-herd">
				<div>
					This page is currently only available to beta customers. If you are a registered beta customer,
					please access the new MINDA experience by logging into{' '}
					<a href={this.context.MINDA_LIVE_URL}>MINDA LIVE</a> and clicking the link on your MINDA LIVE
					homepage.
				</div>
				<br />
				<div>
					To register your interest in becoming a beta customer please email{' '}
					<a href={'mailto:beta@lic.co.nz'}>beta@lic.co.nz</a>.
				</div>
			</div>
		);
	}
}

export default NoSelectedHerd;
