import React, { FunctionComponent } from 'react';
import styles from './NotFound.module.scss';

export const NotFound: FunctionComponent = () => {
	return (
		<aside className={styles.main} role="alert">
			Animal not found
		</aside>
	);
};
