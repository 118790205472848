import i18next from 'i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const i18n = i18next
	.use(Backend)
	.use(LanguageDetector)
	.init({
		fallbackLng: 'en',
		load: 'languageOnly',
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export interface TranslateFunction {
	(key: string): string;
}
