import { Provider as MobxProvider } from 'mobx-react';
import * as React from 'react';
import { AppConfigContext } from './services/context/AppConfigContext';
import { UserData, UserDataContext } from './services/context/UserDataContext';
import { AppState, buildAppInitialState } from './AppInitialStateBuilder';
import LandingPage from './scenes/LandingPage/LandingPage';

class App extends React.Component<{}, AppState> {
	public state: AppState = {
		userData: {},
		groupFound: false,
	};

	public componentDidMount() {
		return buildAppInitialState().then(state => this.setState(state));
	}

	public render() {
		if (!this.state.groupFound) {
			return (
				<div className="error-message">
					We are unable to load this page at this time, please try again later.
				</div>
			);
		}

		// Not logged in, or;
		// Don't have a selectedHerd, or;
		// Don't have the config loaded yet, or;
		// so don't render the landing page yet.
		if (!this.state.userData || !this.state.appConfig) {
			return null;
		}

		const userData: UserData = {
			selectedGroupUrl: this.state.userData.selectedGroupUrl,
			userToken: this.state.userData.userToken!,
		};

		return (
			<MobxProvider {...this.state.rootStore}>
				<AppConfigContext.Provider value={this.state.appConfig}>
					<UserDataContext.Provider value={userData}>
						<LandingPage userData={userData} />
					</UserDataContext.Provider>
				</AppConfigContext.Provider>
			</MobxProvider>
		);
	}
}

export default App;
