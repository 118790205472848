import { computed } from 'mobx';
import { MatingEventRepresentation } from '../representations/MatingEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class MatingEvent extends AnimalEventCommon<MatingEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		const { matingType } = this.representation;
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			matingType,
		};
	}
}
