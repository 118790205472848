import * as mobx from 'mobx';
import { TranslateFunction } from '../i18n';
import { RestClient } from '../rest/RestClient';
import { AppConfig } from '../context/AppConfig';
import { AnimalStore } from './AnimalStore';
import { AnimalGroupStore } from './AnimalGroupStore';
import { UiStore } from './UiStore';

mobx.configure({ enforceActions: 'observed' });

export class RootStore {
	constructor(
		public animalGroupStore: AnimalGroupStore,
		public animalStore: AnimalStore,
		public uiStore: UiStore,
		public appConfig: AppConfig
	) {}
}

export function makeRootStore(restClient: RestClient, translate: TranslateFunction, appConfig: AppConfig): RootStore {
	const animalGroupStore = new AnimalGroupStore(restClient);
	const animalStore = new AnimalStore(animalGroupStore);
	const uiStore = new UiStore(translate);

	return new RootStore(animalGroupStore, animalStore, uiStore, appConfig);
}
