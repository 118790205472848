import { computed } from 'mobx';
import { PregnancyDiagnosisEventRepresentation } from '../representations/PregnancyDiagnosisEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class PregnancyDiagnosisEvent extends AnimalEventCommon<PregnancyDiagnosisEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			pregnancyStatus: this.representation.pregnancyStatus,
			daysPregnant: this.representation.daysPregnant,
		};
	}
}
