import { FeedRepresentation, LinkedRepresentation } from '../representations/Representation';
import { RestHttpClient, RestHttpResponse } from './RestHttpClient';
import {
	findLocationHeader,
	makeFeedRepresentation,
	makeLinkedRepresentation,
	validateBody,
	validateStatus,
} from './RestHttpResponseUtils';
import { findRequiredLinkRelation, singleItemOrUndefined } from './RepresentationUtils';
import { AuthOptions } from './HttpAuthenticationUtils';
import { makeHttpAuthenticationHandler } from './HttpAuthenticationHandler';

export interface RestClient {
	get(uri: string): Promise<LinkedRepresentation>;

	getRelation(representation: LinkedRepresentation, relation: string): Promise<LinkedRepresentation>;

	getItems(representation: FeedRepresentation): Promise<Array<LinkedRepresentation>>;

	getFeed(uri: string): Promise<FeedRepresentation>;

	getSingleItemOrDefault(representation: FeedRepresentation): Promise<LinkedRepresentation | undefined>;

	searchRelation(
		representation: LinkedRepresentation,
		searchCriteria: any,
		relation: string
	): Promise<FeedRepresentation>;

	getFeedRelation(representation: LinkedRepresentation, relation: string): Promise<FeedRepresentation>;
}

function validateForm(response: RestHttpResponse): any {
	// TODO: this should validate the posted parameters against the fields returned by the search-form
	return response;
}

export function makeRestClient(httpClient: RestHttpClient, authOptions: AuthOptions): RestClient {
	const authenticationHandler = makeHttpAuthenticationHandler(httpClient, authOptions);

	const get = (uri: string): Promise<LinkedRepresentation> =>
		authenticationHandler
			.get(uri)
			.then(validateStatus)
			.then(validateBody)
			.then(makeLinkedRepresentation);

	const getItems = (representation: FeedRepresentation): Promise<Array<LinkedRepresentation>> =>
		Promise.all(representation.items.map(item => get(item.id)));

	const getFeed = (uri: string): Promise<FeedRepresentation> =>
		authenticationHandler
			.get(uri)
			.then(validateStatus)
			.then(validateBody)
			.then(makeFeedRepresentation);

	const getSearch = (uri: string): Promise<RestHttpResponse> =>
		authenticationHandler
			.get(uri)
			.then(validateStatus)
			.then(validateBody)
			.then(validateForm);

	const postSearch = (uri: string, searchCriteria: any): Promise<string> =>
		authenticationHandler
			.post(uri, JSON.stringify(searchCriteria))
			.then(validateStatus)
			.then(validateBody)
			.then(findLocationHeader);

	const getSingleItemOrDefault = (representation: FeedRepresentation): Promise<LinkedRepresentation | undefined> => {
		try {
			const item = singleItemOrUndefined(representation);
			return item ? get(item.id) : Promise.resolve(undefined);
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const searchRelation = (representation: LinkedRepresentation, searchCriteria: any, relation: string) => {
		const searchFormUri = findRequiredLinkRelation(representation, relation).href;
		return getSearch(searchFormUri)
			.then(() => postSearch(searchFormUri, searchCriteria))
			.then(location => getFeed(location));
	};

	const getRelation = (representation: LinkedRepresentation, relation: string) => {
		const resourceUri = findRequiredLinkRelation(representation, relation).href;
		return get(resourceUri);
	};

	const getFeedRelation = (representation: LinkedRepresentation, relation: string) => {
		const uri = findRequiredLinkRelation(representation, relation).href;
		return getFeed(uri);
	};

	return { get, getRelation, getItems, getFeed, getSingleItemOrDefault, searchRelation, getFeedRelation };
}
