import { computed } from 'mobx';
import { ManagementNumberEventRepresentation } from '../representations/ManagementNumberEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class ManagementNumberEvent extends AnimalEventCommon<ManagementNumberEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			managementNumber: this.representation.managementNumber,
		};
	}

	isStart(): boolean {
		return this.representation.isStart === true;
	}
}
