import { parseDate } from './parseDate';
import { LinkedRepresentation } from './Representation';

export interface GroupMembershipRepresentation extends LinkedRepresentation {
	_type: string;
	endDate?: string;
	startDate: Date;
}

export function findAnimalCurrentGroupMembership(
	animalMemberships: Array<GroupMembershipRepresentation>
): GroupMembershipRepresentation {
	const currentMemberships = animalMemberships.filter(x => x.endDate === undefined);

	if (currentMemberships.length === 0) {
		throw new Error('Animal doesnt have a current group membership');
	}

	if (currentMemberships.length > 1) {
		throw new Error('Animal has more than one current group membership');
	}

	return currentMemberships[0];
}

export function toGroupMemberships(
	linkedRepresentations: Array<LinkedRepresentation>
): Array<GroupMembershipRepresentation> {
	return linkedRepresentations.map(toGroupMembership);
}

export function toGroupMembership(representation: LinkedRepresentation): GroupMembershipRepresentation {
	const linkRepresentation = representation as any;

	if (linkRepresentation._type !== 'groupMembershipRangeEvent') {
		throw new Error('This is not a groupMembershipRangeEvent');
	}

	const startDate = Date.parse(linkRepresentation.startDate);
	if (!linkRepresentation.startDate || isNaN(startDate)) {
		throw new Error("The resource representation does not contain the mandatory 'startDate' field");
	}

	const groupRepresentation = representation as GroupMembershipRepresentation;
	groupRepresentation.startDate = parseDate(linkRepresentation.startDate);

	return groupRepresentation;
}
