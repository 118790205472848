export interface AppConfig {
	IDENTITY_FRONTEND_ENDPOINT: string;
	LIC_CUSTOMER_ENDPOINT: string;
	LOGOUT_URL: string;
	MINDA_LIVE_URL: string;
	OAUTH_REDIRECT_TO_LOCAL: boolean;
	UI_PROXY_ENDPOINT: string;
	UI_PROXY_CONNECT_PATH: string;
	ANIMAL_GROUP_API_URL: string;
	API_ROOT_URL: string;
	ENVIRONMENT: 'local' | 'dev' | 'accp' | 'prod';
}

export function getAppConfiguration(): Promise<AppConfig> {
	return fetch('/config.json')
		.then(result => result.json())
		.then(configData => {
			return {
				ANIMAL_GROUP_API_URL: configData.ANIMAL_GROUP_API_URL,
				API_ROOT_URL: configData.API_ROOT_URL,
				ENVIRONMENT: configData.ENVIRONMENT,
				IDENTITY_FRONTEND_ENDPOINT: configData.IDENTITY_FRONTEND_ENDPOINT,
				LIC_CUSTOMER_ENDPOINT: configData.LIC_CUSTOMER_ENDPOINT,
				MINDA_LIVE_URL: configData.MINDA_LIVE_URL,
				OAUTH_REDIRECT_TO_LOCAL: configData.OAUTH_REDIRECT_TO_LOCAL,
				UI_PROXY_CONNECT_PATH: configData.UI_PROXY_CONNECT_PATH,
				UI_PROXY_ENDPOINT: configData.UI_PROXY_ENDPOINT,
				LOGOUT_URL: configData.LOGOUT_URL,
			};
		});
}
