import { HttpHeaderKeyValuePair } from './RestHttpClient';

export function makeDefaultGetHttpHeaders(authOptions: AuthOptions): Array<HttpHeaderKeyValuePair> {
	const headers = [{ key: 'Accept', value: 'application/json' }];

	addBearerToken(authOptions, headers);

	return headers;
}

export function makeDefaultPostHttpHeaders(authOptions: AuthOptions): Array<HttpHeaderKeyValuePair> {
	const headers = [{ key: 'Content-Type', value: 'application/json' }];

	addBearerToken(authOptions, headers);

	return headers;
}

export interface AuthOptions {
	bearerToken?: string;
	onAuthenticationFailed?: (authOptions: AuthOptions) => Promise<boolean>;
}

function addBearerToken(authOptions: AuthOptions, headers: Array<HttpHeaderKeyValuePair>) {
	if (authOptions.bearerToken) {
		headers.push({ key: 'Authorization', value: `Bearer ${authOptions.bearerToken}` });
	}
}
