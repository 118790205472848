import * as React from 'react';
import './MindaSwitch.css';

function CloseWindow() {
	window.close();
}

const MindaSwitch = () => (
	<div className="minda-switch">
		<button onClick={CloseWindow}>Exit the new MINDA experience</button>
	</div>
);

export default MindaSwitch;
