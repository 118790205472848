import { RestClient } from '../rest/RestClient';
import { findRequiredLinkRelation } from '../rest/RepresentationUtils';
import { LinkedRepresentation, LinkRelation } from './Representation';
import { AnimalRepresentation } from './AnimalRepresentation';

export class AnimalGroupRepresentation implements LinkedRepresentation {
	_type: string;
	links: Array<LinkRelation>;
	participantCode: string;

	constructor(private restClient: RestClient, representation: LinkedRepresentation) {
		if (representation._type !== 'Group') {
			throw new Error('This is not a Group');
		}

		this._type = representation._type;
		this.links = representation.links;
		this.participantCode = representation.participantCode;
	}

	private get uri(): string {
		return findRequiredLinkRelation(this, 'self').href;
	}

	findAnimal(managementNumber: number): Promise<AnimalRepresentation | undefined> {
		return this.restClient
			.get(this.uri)
			.then(group =>
				this.restClient.searchRelation(
					group,
					{ managementNumber },
					'search-for-current-animal-by-management-number'
				)
			)
			.then(animalFeed => this.restClient.getSingleItemOrDefault(animalFeed))
			.then(animalLinkedRepresentation =>
				animalLinkedRepresentation
					? Promise.resolve(new AnimalRepresentation(this.restClient, animalLinkedRepresentation))
					: Promise.resolve(undefined)
			);
	}
}
