import { RestHttpClient, RestHttpResponse } from './RestHttpClient';
import { AuthOptions, makeDefaultGetHttpHeaders, makeDefaultPostHttpHeaders } from './HttpAuthenticationUtils';

export interface HttpAuthenticationHandler {
	get(uri: string): Promise<RestHttpResponse>;
	post(uri: string, content: any): Promise<RestHttpResponse>;
}

export function makeHttpAuthenticationHandler(
	httpClient: RestHttpClient,
	authOptions: AuthOptions
): HttpAuthenticationHandler {
	return {
		async get(uri: string): Promise<RestHttpResponse> {
			const response = await httpClient.get(uri, makeDefaultGetHttpHeaders(authOptions));

			if (response.status === 401 && authOptions.onAuthenticationFailed) {
				return authOptions
					.onAuthenticationFailed(authOptions)
					.then(retry =>
						retry ? httpClient.get(uri, makeDefaultGetHttpHeaders(authOptions)) : Promise.resolve(response)
					);
			}
			return Promise.resolve(response);
		},

		async post(uri: string, content: any): Promise<RestHttpResponse> {
			const response = await httpClient.post(uri, content, makeDefaultPostHttpHeaders(authOptions));

			if (response.status === 401 && authOptions.onAuthenticationFailed) {
				return authOptions
					.onAuthenticationFailed(authOptions)
					.then(retry =>
						retry
							? httpClient.post(uri, content, makeDefaultPostHttpHeaders(authOptions))
							: Promise.resolve(response)
					);
			}
			return Promise.resolve(response);
		},
	};
}
