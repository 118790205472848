import React from 'react';
import { AppConfigContext } from '../../services/context/AppConfigContext';
import LandingPageGrid from './components/LandingPageGrid/LandingPageGrid';
import { TileMetaData, TileType } from './components/LandingPageGrid/TileMetaData';

interface LandingPageContainerState {
	tiles: Array<TileMetaData>;
}
interface LandingPageContainerProps {
	userToken: string;
	selectedGroupUrl: string;
}
export interface ApiTiles {
	animalGroupUrn: string;
	subjectUrn: string;
	createdAt: string;
	createdBy: string;
	externalId: string;
	lastModifiedAt: string;
	lastModifiedBy: string;
	tileType: TileType;
}

export class LandingPageContainer extends React.Component<LandingPageContainerProps, LandingPageContainerState> {
	static contextType = AppConfigContext;
	constructor(props: LandingPageContainerProps) {
		super(props);
		this.state = { tiles: [] };
	}

	async componentDidMount() {
		const tiles = await getTiles(this.props.userToken, this.props.selectedGroupUrl, this.context.API_ROOT_URL);
		const tileMetaData = mapTilesToTileMetadata(tiles);
		this.setState({ tiles: tileMetaData });
	}

	render() {
		return <LandingPageGrid tiles={this.state.tiles} />;
	}
}

export async function getTiles(userToken: string, selectedGroupUrl: string, rootUrl: string) {
	const headers: any = {
		Authorization: `Bearer ${userToken}`,
	};
	const groupId = selectedGroupUrl.split('/').pop();
	const noTiles: Array<ApiTiles> = [];
	try {
		const response = await fetch(`${rootUrl}/tiles/${groupId}`, { headers, method: 'get' });

		if (!response.ok) {
			return noTiles;
		}
		const tilesFromAPI: Array<ApiTiles> = (await response.json()).tiles;
		return tilesFromAPI;
	} catch (err) {
		return noTiles;
	}
}

export function mapTilesToTileMetadata(tiles: Array<ApiTiles>): Array<TileMetaData> {
	return tiles.map(tile => {
		return {
			id: `${tile.animalGroupUrn}-${tile.subjectUrn}-${tile.tileType}`,
			type: tile.tileType,
			tileContext: { urn: tile.subjectUrn },
		} as TileMetaData;
	});
}
