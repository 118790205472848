import { RestClient } from '../rest/RestClient';
// eslint-disable-next-line import/no-cycle
import { CalvingEventRepresentation, toCalvingEvents } from './CalvingEventRepresentation';
import { GroupMembershipRepresentation, toGroupMemberships } from './GroupMembershipRepresentation';
import { HealthConditionEventRepresentation, toHealthConditionEvents } from './HealthConditionEventRepresentation';
import { LinkRelationName } from './LinkRelationName';
import {
	PregnancyDiagnosisEventRepresentation,
	toPregnancyDiagnosisEvents,
} from './PregnancyDiagnosisEventRepresentation';
import { LinkedRepresentation, LinkRelation } from './Representation';
import { MatingEventRepresentation, toMatingEvents } from './MatingEventRepresentation';
import { HeatEventRepresentation, toHeatEvents } from './HeatEventRepresentation';
import { LiveWeightEventRepresentation, toLiveWeightEvents } from './LiveWeightEventRepresentation';
import { InMilkEventRepresentation, toInMilkEvents } from './InMilkEventRepresentation';
import { ManagementNumberEventRepresentation, toManagementNumberEvents } from './ManagementNumberEventRepresentation';

export interface AnimalBreed {
	abbreviation: string;
	portion16th: number;
}

export interface AnimalBirthId {
	prefix: string;
	year: number;
	sequence: number;
}

export class AnimalRepresentation implements LinkedRepresentation {
	_type: string;
	links: Array<LinkRelation>;

	animalKey?: number;
	birthDate?: Date;
	birthId?: AnimalBirthId;
	sex?: string;
	identifiers: Array<string>;
	breeds: Array<AnimalBreed>;

	groupMemberships: Array<GroupMembershipRepresentation> = [];
	calvingEvents: Array<CalvingEventRepresentation> = [];
	matingEvents: Array<MatingEventRepresentation> = [];
	pregnancyDiagnosisEvents: Array<PregnancyDiagnosisEventRepresentation> = [];
	healthConditionEvents: Array<HealthConditionEventRepresentation> = [];
	heatEvents: Array<HeatEventRepresentation> = [];
	liveWeightEvents: Array<LiveWeightEventRepresentation> = [];
	inMilkEvents: Array<InMilkEventRepresentation> = [];
	managementNumberEvents: Array<ManagementNumberEventRepresentation> = [];

	constructor(private restClient: RestClient, representation: LinkedRepresentation) {
		if (representation._type !== 'Animal') {
			throw new Error(`This is not a 'Animal'`);
		}

		this._type = representation._type;
		this.links = representation.links;
		this.animalKey = representation.animalKey;
		this.birthDate = representation.birthDate;
		this.birthId = representation.birthId;
		this.sex = representation.sex;
		this.identifiers = representation.identifiers;
		this.breeds = representation.breeds;
	}

	hydrate(): Promise<void> {
		return Promise.all([
			this.hydrateCalvingEvents(),
			this.hydrateMatingEvents(),
			this.hydrateGroupMembershipEvents(),
			this.hydratePregnancyDiagnosisEvents(),
			this.hydrateHeatEvents(),
			this.hydrateHealthConditionEvents(),
			this.hydrateLiveWeightEvents(),
			this.hydrateInMilkEvents(),
			this.hydrateManagementNumberEvents(),
		]).then(() => {});
	}

	private hydrateGroupMembershipEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, 'group-membership-events')
			.then(groupMembershipRangesFeed => this.restClient.getItems(groupMembershipRangesFeed))
			.then(groupMemberships => toGroupMemberships(groupMemberships))
			.then(groupMembershipsPresentation => {
				this.groupMemberships.push(...groupMembershipsPresentation);
			});
	}

	private hydrateCalvingEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.CalvingEvents)
			.then(calvingEventFeed => this.restClient.getItems(calvingEventFeed))
			.then(calvingEvents => toCalvingEvents(this.restClient, calvingEvents))
			.then(calvingEvents =>
				Promise.all(
					calvingEvents.map(calvingEvent => {
						return calvingEvent.hydrate();
					})
				)
			)
			.then(calvingEvents => {
				this.calvingEvents.push(...calvingEvents);
			});
	}

	private hydrateMatingEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.MatingEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toMatingEvents(feedItems))
			.then(events => {
				this.matingEvents.push(...events);
			});
	}

	private hydratePregnancyDiagnosisEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.PregnancyDiagnoses)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toPregnancyDiagnosisEvents(feedItems))
			.then(events => {
				this.pregnancyDiagnosisEvents.push(...events);
			});
	}

	private hydrateHeatEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.HeatEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toHeatEvents(feedItems))
			.then(events => {
				this.heatEvents.push(...events);
			});
	}

	private hydrateHealthConditionEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.HealthConditionEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toHealthConditionEvents(feedItems, this.restClient))
			.then(events => Promise.all(events.map(event => event.hydrate())))
			.then(events => {
				this.healthConditionEvents.push(...events);
			});
	}

	private hydrateLiveWeightEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.LiveWeightEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toLiveWeightEvents(feedItems))
			.then(events => {
				this.liveWeightEvents.push(...events);
			});
	}

	private hydrateInMilkEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.InMilkEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toInMilkEvents(feedItems))
			.then(events => {
				this.inMilkEvents.push(...events);
			});
	}

	private hydrateManagementNumberEvents(): Promise<void> {
		return this.restClient
			.getFeedRelation(this, LinkRelationName.ManagementIdEvents)
			.then(feed => this.restClient.getItems(feed))
			.then(feedItems => toManagementNumberEvents(feedItems))
			.then(events => {
				this.managementNumberEvents.push(...events);
			});
	}
}
