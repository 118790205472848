import { computed } from 'mobx';
import { DDMMMMYYYY, ifDefined } from '../formatters/formatters';
import {
	AffectedQuartersRepresentation,
	HealthConditionEventRepresentation,
} from '../representations/HealthConditionEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class HealthConditionEvent extends AnimalEventCommon<HealthConditionEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			healthCondition: ifDefined(
				this.representation.healthCondition,
				healthCondition => healthCondition.description
			),
			healthProduct: ifDefined(this.representation.healthProduct, healthProduct => healthProduct.description),
			affectedQuarters: formatAffectedQuarters(this.representation.affectedQuarters),
			dateOfDosage: ifDefined(this.representation.dateOfDosage, DDMMMMYYYY),
			dosageCount: this.representation.dosageCount,
			dosageUnit: this.representation.dosageUnit,
			withholdingHoursForMilk: this.representation.withholdingHoursForMilk,
			withholdingHoursForMeat: this.representation.withholdingHoursForMeat,
			dateOfReturnToVat: ifDefined(this.representation.dateOfReturnToVat, DDMMMMYYYY),
			veterinarian: this.representation.veterinarian,
		};
	}
}

function formatAffectedQuarters(affectedQuarters: AffectedQuartersRepresentation): string {
	const arr = [];
	if (affectedQuarters.frontLeft) {
		arr.push('LF');
	}
	if (affectedQuarters.frontRight) {
		arr.push('RF');
	}
	if (affectedQuarters.rearLeft) {
		arr.push('LR');
	}
	if (affectedQuarters.rearRight) {
		arr.push('RR');
	}
	return arr.join(' ');
}
