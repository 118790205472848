export interface Clock {
	now(): Date;
}

export class RealtimeClock implements Clock {
	now(): Date {
		return new Date();
	}
}

export class FakeClock implements Clock {
	constructor(private date: Date) {}

	now(): Date {
		return this.date;
	}
}
