import { computed } from 'mobx';
import { AnimalGroup } from '../domain/AnimalGroup';
import { RestClient } from '../rest/RestClient';
import { AnimalGroupRepresentation } from '../representations/AnimalGroupRepresentation';

export class AnimalGroupStore {
	private group?: AnimalGroup;

	constructor(private restClient: RestClient) {}

	@computed get animalGroup(): AnimalGroup | undefined {
		return this.group;
	}

	loadGroup(groupUri: string): Promise<AnimalGroup> {
		return this.restClient
			.get(groupUri)
			.then(
				linkedRepresentation =>
					new AnimalGroup(new AnimalGroupRepresentation(this.restClient, linkedRepresentation))
			)
			.then(animalGroup => (this.group = animalGroup));
	}
}
