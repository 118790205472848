export interface TileMetaData {
	/**
	 * A unique id used to disambiguate tiles from each other.
	 * This id is only used internally within the landing page and is not shared with the tiles themselves.
	 */
	id: string;
	/**
	 * The type of the tile used to match this tile object to the react component that should be used to render this data.
	 */
	type: TileType;
	/**
	 * A blob of data that will be passed to the react component that renders this tile.
	 */
	tileContext?: any;
}

// these names must match the type string in the tile-service
// prettier-ignore
export enum TileType {
	HerdTestReadiness = "herd-test-readiness",
	AnimalReplacement = "animal-replacement",
	FakeTile = "fake_tile",
	HerdTestResult = "herd-test-result"
}
