import React from 'react';
import { AppConfig } from './AppConfig';

export const AppConfigContext = React.createContext<AppConfig>({
	ANIMAL_GROUP_API_URL: '',
	API_ROOT_URL: '',
	ENVIRONMENT: 'dev',
	IDENTITY_FRONTEND_ENDPOINT: '',
	LIC_CUSTOMER_ENDPOINT: '',
	LOGOUT_URL: '',
	MINDA_LIVE_URL: '',
	OAUTH_REDIRECT_TO_LOCAL: false,
	UI_PROXY_CONNECT_PATH: '',
	UI_PROXY_ENDPOINT: '',
});
