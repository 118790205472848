import { computed } from 'mobx';
import { LiveWeightEventRepresentation } from '../representations/LiveWeightEventRepresentation';
import { AnimalEventCommon } from './AnimalEventCommon';
import { DialogueDisplayProps } from './DialogueDisplayProps';

export class LiveWeightEvent extends AnimalEventCommon<LiveWeightEventRepresentation> {
	@computed
	get displayProperties(): DialogueDisplayProps {
		return {
			occurred: this.dateOfOnFarmEventFormatted,
			weight: this.representation.weight,
		};
	}
}
