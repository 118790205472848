import classNames from 'classnames';
import * as React from 'react';
import { LoadingAnimation } from '../../../../components/LoadingAnimation/LoadingAnimation';
import './LandingPageGrid.css';
import { TileMapper, TileMapperProps } from './TileMapper';
import { TileType, TileMetaData } from './TileMetaData';

interface TileState {
	id: string;
	type: TileType;
	tileContext?: any;
	tileIsReady?: boolean;
}

interface LandingPageGridProps {
	tiles: Array<TileMetaData>;
}

interface LandingPageGridState {
	detailViewTileId?: string;
	tiles: Map<string, TileState>;
}

class LandingPageGrid extends React.Component<LandingPageGridProps, LandingPageGridState> {
	static getDerivedStateFromProps(props: LandingPageGridProps, state: LandingPageGridState) {
		return {
			tiles: new Map(
				props.tiles.map((tile: TileMetaData) => {
					let tileIsReadyFromState: boolean | undefined = false;
					const tileFromState = state.tiles.get(tile.id);
					if (tileFromState) {
						tileIsReadyFromState = tileFromState.tileIsReady;
					}
					return [tile.id.toString(), { ...tile, tileIsReady: tileIsReadyFromState }] as [string, TileState];
				})
			),
		};
	}

	constructor(props: LandingPageGridProps) {
		super(props);
		this.state = {
			tiles: new Map(props.tiles.map(tile => [tile.id, { ...tile, tileIsReady: false }] as [string, TileState])),
		};
	}

	public onSelectDetailTile(detailViewTileId?: string): void {
		this.setState({ detailViewTileId });
	}

	public onCloseDetailTile(): void {
		this.onSelectDetailTile(undefined);
	}

	public onTileIsReady(tileId: string): void {
		// Create a new object to pass to setState.
		const tiles = new Map(this.state.tiles);
		const tileState = tiles.get(tileId);
		if (!tileState) {
			throw Error(`Could not find tile state for id: '${tileId}'.`);
		}
		tileState.tileIsReady = true;
		this.setState({ tiles });
	}

	public render() {
		// Convert the tile state into an array so that we can .map() it into react components.
		const tileStates = Array.from(this.state.tiles.values());

		return (
			<div>
				<div className="grid-container">
					{tileStates.map(tileState => {
						const isDetailTile = this.isDetailTile(tileState.id);
						const onSelectDetailTile = () => this.onSelectDetailTile(tileState.id);

						const classes = classNames('tile', {
							'tile-detail-view': isDetailTile,
							'tile-not-ready': !tileState.tileIsReady,
							'tile-ready': tileState.tileIsReady,
						});
						const tileClasses = classNames({
							hidden: !tileState.tileIsReady,
						});

						const props: TileMapperProps = {
							/** Wrap closeDetailTile in a function closure so that "this" is bound. */
							closeDetailTileHandler: () => this.onCloseDetailTile(),
							isDetailTile,
							onTileIsReady: () => this.onTileIsReady(tileState.id),
							tileMetaData: tileState,
						};

						return (
							<div className={classes} key={tileState.id} onClick={onSelectDetailTile}>
								{/* We render the tile  component so that it starts fetching data, and also render the loading animation until the tile is "ready". */}
								<div className={tileClasses}>
									<TileMapper {...props} />
								</div>
								{!tileState.tileIsReady && <LoadingAnimation />}
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	private isDetailTile(tileId: string): boolean {
		return this.state && tileId === this.state.detailViewTileId;
	}
}

export default LandingPageGrid;
