import React, { Component } from 'react';
import { AnimalSummary } from './components/AnimalSummary/AnimalSummary';
import { Header } from './components/Header/Header';
import { AnimalTimeline } from './components/AnimalTimeline/AnimalTimeline';

export class AnimalProfile extends Component {
	render() {
		return (
			<>
				<Header />
				<AnimalSummary />
				<AnimalTimeline />
			</>
		);
	}
}
