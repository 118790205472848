import React, { FunctionComponent } from 'react';

import './Header.scss';
import { AnimalLookup } from './components/AnimalLookup/AnimalLookup';
import { MainNavbar } from './components/MainNavbar/MainNavbar';

export const Header: FunctionComponent<any> = () => {
	return (
		<header>
			<AnimalLookup className="search" />
			<MainNavbar />
		</header>
	);
};
