import classNames from 'classnames';
import * as React from 'react';
import TileContract from '../../../../../../services/tiles/TileContract';
import './FakeTile.css';

interface FakeTileState {
	goalUrls: string[];
}

/** A tile for testing and demonstration purposes only. */
export class FakeTile extends React.Component<TileContract, FakeTileState> {
	constructor(props: TileContract) {
		super(props);
		this.state = {
			goalUrls: [],
		};
	}

	public componentDidMount() {
		fetch(`${this.props.apiRootUrl}/goal/goals`, {
			headers: {
				Authorization: `Bearer ${this.props.userToken}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			method: 'get',
		})
			.then(async res => {
				const jsonObj = await res.json();
				const goalUrls = jsonObj.items.map((goal: any) => goal.id);
				this.setState({ goalUrls }, this.props.onReady);
			})
			.catch(this.props.onReady);
	}

	public render() {
		const buttonClasses = classNames('close-button', { hidden: this.props.viewMode === 'tile' });
		return (
			<div className="fake-tile">
				<span>
					Example Goal {this.props.tileContext.urn} ({this.props.viewMode} mode)
				</span>
				<strong className={buttonClasses} onClick={this.switchToDetailView}>
					X
				</strong>
				<div>Animal Group {this.props.animalGroupUrl}</div>
				{this.state.goalUrls.map(goal => (
					<div key={goal}>{goal}</div>
				))}
			</div>
		);
	}

	private switchToDetailView = (event: React.MouseEvent) => {
		this.props.onDetailClose();
		event.stopPropagation();
		event.preventDefault();
	};
}
