import React from 'react';

export interface UserData {
	selectedGroupUrl?: string;
	userToken: string;
}

export const UserDataContext = React.createContext<UserData>({
	selectedGroupUrl: '',
	userToken: '',
});
