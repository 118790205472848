import React, { FunctionComponent } from 'react';
import { bindToMobx } from '../../../../../../services/context/bindToMobx';
import { Animal } from '../../../../../../services/domain/Animal';
import { RootStore } from '../../../../../../services/stores/RootStore';
import { UiStore } from '../../../../../../services/stores/UiStore';
import styles from './AnimalSummary.module.scss';
import { AnimalDetailItem } from './components/AnimalDetailItem/AnimalDetailItem';
import { timer } from './images/index';

interface AnimalSummaryComponentProps {
	animal?: Animal;
	uiStore: UiStore;
}

export const AnimalSummaryComponent: FunctionComponent<AnimalSummaryComponentProps> = props => {
	const animal = props.animal;
	if (!animal) {
		return null;
	}

	const handleButtonClick = () => {
		props.uiStore.toggleTimelineVisible();
	};

	return (
		<div className={styles.main}>
			<div className={styles.summarySection}>
				<AnimalDetailItem label="Management number" value={animal.managementNumber} />
				<AnimalDetailItem label="Birth ID" value={animal.birthIdentifier} />
				<AnimalDetailItem label="EID" value={animal.eid} />
				<AnimalDetailItem label="Sex" value={animal.sex} />
			</div>
			<div className={styles.summarySection}>
				<AnimalDetailItem label="Birth date" value={animal.birthDate} />
				<AnimalDetailItem label="Breed" value={animal.breedIdentifier} />
				<AnimalDetailItem label="Entered herd" value={animal.enteredHerd} />
			</div>
			<div className={styles.summarySection}>
				<AnimalDetailItem label="BW" value="Unavailable" />
				<AnimalDetailItem label="PW" value="Unavailable" />
				<AnimalDetailItem label="LW" value="Unavailable" />
				<button className={styles.viewTimelinebutton} onClick={handleButtonClick}>
					<img src={timer} className={styles.clockImage} alt="Timeline" />
					<span>View animal timeline</span>
				</button>
			</div>
		</div>
	);
};

function getProps(rootStore: RootStore): AnimalSummaryComponentProps {
	return {
		animal: rootStore.animalStore.animal,
		uiStore: rootStore.uiStore,
	};
}

export const AnimalSummary = bindToMobx(getProps, AnimalSummaryComponent);
