import * as React from 'react';
import './LoadingAnimation.css';

export function LoadingAnimation(): JSX.Element {
	return (
		<div className="loading-animation">
			<div />
			<div />
			<div />
			<div />
		</div>
	);
}
