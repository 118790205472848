// todo: re-enable these lines when the animal replacement tile is ready
// import AnimalReplacementTile from "@licnz/animal-replacement-tile";
import HerdTestReadiness from '@licnz/ht-readiness-ui';
import HerdTestResults from '@licnz/herd-test-results-ui';
import * as React from 'react';
import { AppConfigContext } from '../../../../services/context/AppConfigContext';
import { UserData, UserDataContext } from '../../../../services/context/UserDataContext';
import TileContract from '../../../../services/tiles/TileContract';
import { AppConfig } from '../../../../services/context/AppConfig';
import { FakeTile } from './components/FakeTile/FakeTile';
import { TileMetaData, TileType } from './TileMetaData';

interface TileMapperComponentProps extends TileMapperProps {
	/** The animal-animalGroup url endpoint of the currently selected herd in the landing page. */
	animalGroupUrl: string;
	/** The user's authentication bearer token to be used in future API calls. */
	userToken: string;
	/** API root URL sourced from the environment specific context object. */
	apiRootUrl: string;
}

/** Returns a tile component based on the tile's type. */
const TileMapperComponent = (props: TileMapperComponentProps) => {
	const tileProps: TileContract = {
		animalGroupUrl: props.animalGroupUrl,
		apiRootUrl: props.apiRootUrl,
		onDetailClose: props.closeDetailTileHandler,
		onReady: props.onTileIsReady,
		tileContext: props.tileMetaData.tileContext,
		userToken: props.userToken,
		viewMode: props.isDetailTile ? 'detail' : 'tile',
	};

	switch (props.tileMetaData.type) {
		case TileType.FakeTile:
			return <FakeTile key={props.tileMetaData.id} {...tileProps} />;
		case TileType.HerdTestReadiness:
			return <HerdTestReadiness key={props.tileMetaData.id} {...tileProps} />;
		case TileType.HerdTestResult:
			return <HerdTestResults key={props.tileMetaData.id} {...tileProps} />;
		// todo: re-enable these lines when the animal replacement tile is ready
		// case TileType.AnimalReplacement:
		//   return <AnimalReplacementTile key={props.tileMetaData.id} {...tileProps} />
		default:
			console.warn(`No TileType mapping found for ${props.tileMetaData.type}`);
			return null;
	}
};

export interface TileMapperProps {
	tileMetaData: TileMetaData;
	closeDetailTileHandler: () => void;
	onTileIsReady: () => void;
	isDetailTile: boolean;
}

export const TileMapper = (props: TileMapperProps) => (
	<UserDataContext.Consumer>
		{(userContext: UserData) => (
			<AppConfigContext.Consumer>
				{(appContext: AppConfig) => (
					<TileMapperComponent
						animalGroupUrl={userContext.selectedGroupUrl!}
						userToken={userContext.userToken}
						apiRootUrl={appContext.API_ROOT_URL}
						{...props}
					/>
				)}
			</AppConfigContext.Consumer>
		)}
	</UserDataContext.Consumer>
);
