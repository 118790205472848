import { ifDefined } from '../formatters/formatters';
import { findLinkRelation } from '../rest/RepresentationUtils';
import { RestClient } from '../rest/RestClient';
import { parseDate } from './parseDate';
import { LinkedRepresentation, LinkRelation } from './Representation';
import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';

export enum HealthConditionEventRelation {
	Product = 'product',
	Condition = 'condition',
}

export interface AffectedQuartersRepresentation {
	frontLeft?: boolean;
	frontRight?: boolean;
	rearLeft?: boolean;
	rearRight?: boolean;
}

export class HealthConditionEventRepresentation implements AnimalEventRepresentation {
	_type: string;
	links: Array<LinkRelation>;
	dateOfOnFarmEvent: Date;

	affectedQuarters: AffectedQuartersRepresentation;
	conditionCategoryCode: string;
	dateOfDosage?: Date;
	dateOfReturnToVat?: Date;
	dosageCount: number;
	dosageUnit: string;
	dryCow: boolean;
	healthCondition?: { code: string; description: string };
	healthProduct?: { code: string; description: string };
	healthRecordId: number;
	veterinarian: string;
	withholdingHoursForMeat: number;
	withholdingHoursForMilk: number;

	constructor(representation: LinkedRepresentation, private restClient: RestClient) {
		const { _type, links, dateOfOnFarmEvent } = toAnimalEvent(representation, 'healthConditionEvent');
		this._type = _type;
		this.links = links;
		this.dateOfOnFarmEvent = dateOfOnFarmEvent;

		this.affectedQuarters = toAffectedQuarters(representation.affectedQuarters);
		this.conditionCategoryCode = representation.conditionCategoryCode;
		this.dateOfDosage = ifDefined(representation.dateOfDosage, parseDate);
		this.dateOfReturnToVat = ifDefined(representation.dateOfReturnToVat, parseDate);
		this.dosageCount = representation.dosageCount;
		this.dosageUnit = representation.dosageUnit;
		this.dryCow = representation.dryCow;
		this.veterinarian = representation.veterinarian;
		this.withholdingHoursForMeat = representation.withholdingHoursForMeat;
		this.withholdingHoursForMilk = representation.withholdingHoursForMilk;
		this.healthRecordId = representation.healthRecordId;
	}

	hydrate(): Promise<HealthConditionEventRepresentation> {
		return Promise.all([this.hydrateCondition(), this.hydrateProduct()]).then(() => this);
	}

	private hydrateCondition(): Promise<void> {
		const relation = findLinkRelation(this, HealthConditionEventRelation.Condition);
		if (!relation) {
			return Promise.resolve();
		}

		return this.restClient.get(relation.href).then((conditionRepresentation: LinkedRepresentation) => {
			this.healthCondition = {
				code: conditionRepresentation.code,
				description: conditionRepresentation.description,
			};
		});
	}

	private hydrateProduct(): Promise<void> {
		const relation = findLinkRelation(this, HealthConditionEventRelation.Product);
		if (!relation) {
			return Promise.resolve();
		}

		return this.restClient.get(relation.href).then((productRepresentation: LinkedRepresentation) => {
			this.healthProduct = {
				code: productRepresentation.code,
				description: productRepresentation.description,
			};
		});
	}
}

export function toHealthConditionEvents(
	linkedRepresentations: Array<LinkedRepresentation>,
	restClient: RestClient
): Array<HealthConditionEventRepresentation> {
	return linkedRepresentations.map(
		linkedRepresentation => new HealthConditionEventRepresentation(linkedRepresentation, restClient)
	);
}

function toAffectedQuarters(affectedQuarters?: AffectedQuartersRepresentation): AffectedQuartersRepresentation {
	const defaultAffectedQuarters = {
		frontLeft: false,
		frontRight: false,
		rearLeft: false,
		rearRight: false,
	};
	return { ...defaultAffectedQuarters, ...affectedQuarters };
}
