import { computed } from 'mobx';
import { AnimalGroupRepresentation } from '../representations/AnimalGroupRepresentation';
import { Animal } from './Animal';

export class AnimalGroup {
	constructor(private representation: AnimalGroupRepresentation) {}

	@computed get participantCode(): string {
		return this.representation.participantCode;
	}

	findGroupAnimal(managementNumber: number): Promise<Animal | undefined> {
		return this.representation
			.findAnimal(managementNumber)
			.then(animalRepresentation => (animalRepresentation ? new Animal(animalRepresentation) : undefined));
	}
}
