import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { AnimalEvent } from '../../../../../../../../../../services/domain/AnimalEventCommon';
import { AnimalEventModal } from '../AnimalEventModal/AnimalEventModal';
import styles from './AnimalEventMarker.module.scss';

interface AnimalEventMarkerProps {
	animalEvent: AnimalEvent;
	translateNamespace: string;
	onEventSelectionChanged?: (isSelected: boolean) => void;
}

interface AnimalEventMarkerState {
	eventIsSelected: boolean;
}

const overlayStyles = {
	background: 'none',
};

const contentStyles = {
	border: 0,
	borderRadius: '8px',
	width: '360px',
	padding: '16px',
};

@observer
export class AnimalEventMarker extends Component<AnimalEventMarkerProps, AnimalEventMarkerState> {
	constructor(props: AnimalEventMarkerProps) {
		super(props);
		this.state = { eventIsSelected: false };
	}

	toggleIsSelected = () => {
		const newSelectionState = !this.state.eventIsSelected;

		this.setState({ eventIsSelected: newSelectionState });

		if (this.props.onEventSelectionChanged) {
			this.props.onEventSelectionChanged(newSelectionState);
		}
	};

	render() {
		const { animalEvent, translateNamespace } = this.props;

		return (
			<Popup
				trigger={
					<div
						className={classNames(styles.main, this.state.eventIsSelected ? styles.selected : '')}
						style={{ left: `${animalEvent.periodOffset}%` }}
					/>
				}
				modal
				contentStyle={contentStyles}
				overlayStyle={overlayStyles}
				onOpen={this.toggleIsSelected}
				onClose={this.toggleIsSelected}
			>
				{close => (
					<AnimalEventModal close={close} animalEvent={animalEvent} translateNamespace={translateNamespace} />
				)}
			</Popup>
		);
	}
}
