// import fetch from "fetch-with-proxy";
import { HttpHeaderKeyValuePair, RestHttpClient, RestHttpResponse } from './RestHttpClient';

export function makeFetchHttpClient(): RestHttpClient {
	const parseHttpResponseHeaders = (responseHeaders: any): Array<HttpHeaderKeyValuePair> =>
		Array.from(responseHeaders.keys()).map(key => ({ key: key as string, value: responseHeaders.get(key) }));

	const makeRequest = (method: string, requestHeaders: Array<HttpHeaderKeyValuePair>, body: any = undefined): any => {
		const request = { method, headers: {}, body };
		requestHeaders.forEach(x => (request.headers[x.key] = x.value));
		return request;
	};

	const makeHttpResponse = (requestUri: string, requestVerb: string, response: any): Promise<RestHttpResponse> =>
		response.text().then((responseText: any) => ({
			requestUri,
			requestVerb,
			status: response.status,
			bodyText: responseText,
			headers: parseHttpResponseHeaders(response.headers),
		}));

	return {
		get: (uri: string, requestHeaders: Array<HttpHeaderKeyValuePair>): Promise<RestHttpResponse> =>
			fetch(uri, makeRequest('GET', requestHeaders)).then(response => makeHttpResponse(uri, 'GET', response)),

		post: (
			uri: string,
			content: string,
			requestHeaders: Array<HttpHeaderKeyValuePair>
		): Promise<RestHttpResponse> =>
			fetch(uri, makeRequest('POST', requestHeaders, content)).then(response =>
				makeHttpResponse(uri, 'POST', response)
			),
	};
}
