import { FeedItem, FeedRepresentation, LinkedRepresentation, LinkRelation } from '../representations/Representation';

export const singleItemOrUndefined: (representation: FeedRepresentation) => FeedItem | undefined = representation => {
	if (representation.items.length > 1) {
		throw new Error('Feed has more than one item');
	}

	if (representation.items.length === 0) {
		return undefined;
	}

	return representation.items[0];
};

export const findRequiredLinkRelation: (representation: LinkedRepresentation, relation: string) => LinkRelation = (
	representation,
	relation
) => {
	const linkRelation = findLinkRelation(representation, relation);
	if (!linkRelation) {
		throw new Error(`link relation "${relation}" not found`);
	}

	return linkRelation;
};

export const findLinkRelation: (representation: LinkedRepresentation, relation: string) => LinkRelation | undefined = (
	representation,
	relation
) => {
	return representation.links.find(link => link.rel === relation);
};
