import { Logger } from '../Logger';
import { RestClient } from '../rest/RestClient';
import { findLinkRelation } from '../rest/RepresentationUtils';
import { LinkedRepresentation, LinkRelation } from './Representation';
import { AnimalEventRepresentation, parseAnimalEventDateOfOnFarmEvent } from './AnimalEventRepresentation';
// eslint-disable-next-line import/no-cycle
import { AnimalRepresentation } from './AnimalRepresentation';

export enum CalvingEventRelation {
	Calf = 'calf',
}

export class CalvingEventRepresentation implements AnimalEventRepresentation {
	_type: string;
	links: Array<LinkRelation>;
	dateOfOnFarmEvent: Date;

	calf?: AnimalRepresentation;

	calfSex: string;
	calfFate: string;
	pregnancyTermination?: string;
	sequence: number;

	constructor(
		private restClient: RestClient,
		representation: LinkedRepresentation,
		private logger: Logger = console
	) {
		if (representation._type !== 'calvingEvent') {
			throw new Error('This is not a calving event');
		}

		this._type = representation._type;
		this.links = representation.links;
		this.calfSex = representation.calfSex;
		this.calfFate = representation.calfFate;
		this.pregnancyTermination = representation.pregnancyTermination;
		this.sequence = representation.sequence;
		this.dateOfOnFarmEvent = parseAnimalEventDateOfOnFarmEvent(representation);
	}

	hydrate(): Promise<CalvingEventRepresentation> {
		const calfRelation = findLinkRelation(this, CalvingEventRelation.Calf);
		if (!calfRelation) {
			return Promise.resolve(this);
		}

		return this.restClient
			.get(calfRelation.href)
			.then(calf => {
				this.calf = new AnimalRepresentation(this.restClient, calf);
			})
			.then(() => this)
			.catch(error => {
				// if farmers sell the calf they will no longer have access to that animal.
				// ideally we should change that behaviour and allow access to that animal.
				if (error.responseStatus === 403) {
					this.logger.log(
						`Unexpected response for calf, maybe because calf is sold so farmer is not authorized. Error: ${error}`
					);
					return this;
				}
				throw error;
			});
	}
}

export function toCalvingEvents(
	restClient: RestClient,
	linkedRepresentations: Array<LinkedRepresentation>
): Array<CalvingEventRepresentation> {
	return linkedRepresentations.map(x => new CalvingEventRepresentation(restClient, x));
}
