import { parseDate } from './parseDate';
import { LinkedRepresentation, LinkRelation } from './Representation';

export interface AnimalEventRepresentation {
	_type: string;
	links: Array<LinkRelation>;
	dateOfOnFarmEvent: Date;
}

export function toAnimalEvent<T extends AnimalEventRepresentation>(
	representation: LinkedRepresentation,
	type: string
): T {
	if (representation._type !== type) {
		throw new Error(`This is not a '${type}'`);
	}

	const dateOfOnFarmEvent = parseAnimalEventDateOfOnFarmEvent(representation);
	const animalEventRepresentation = (representation as any) as T;
	animalEventRepresentation.dateOfOnFarmEvent = dateOfOnFarmEvent;

	return animalEventRepresentation;
}

export function parseAnimalEventDateOfOnFarmEvent(animalEventRepresentation: LinkedRepresentation): Date {
	const dateOfOnFarmEvent = Date.parse(animalEventRepresentation.dateOfOnFarmEvent);
	if (!animalEventRepresentation.dateOfOnFarmEvent || isNaN(dateOfOnFarmEvent)) {
		throw new Error(
			`The animalEventRepresentation type:${
				animalEventRepresentation._type
			} does not contain the mandatory 'dateOfOnFarmEvent' field`
		);
	}

	return parseDate(animalEventRepresentation.dateOfOnFarmEvent);
}
