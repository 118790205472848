import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './index.css';
import './apollo.style.css';
import './booster-fy.css';
import 'rbx/index.css';
import 'bulma/css/bulma.css';
import './assets/css/lic-styles.css';

import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
unregisterServiceWorker();
