import { compareAsc, differenceInCalendarDays, isWithinRange } from 'date-fns';
import { AnimalEvent } from './AnimalEventCommon';

export function dateOfOnFarmEventAscending(event1: AnimalEvent, event2: AnimalEvent): number {
	return compareAsc(event1.dateOfOnFarmEvent!, event2.dateOfOnFarmEvent!);
}

export function eventWithinRange(from: Date, to: Date): (event: AnimalEvent) => boolean {
	return (event: AnimalEvent) => {
		return isWithinRange(event.dateOfOnFarmEvent!, from, to);
	};
}

export function setPeriodOffset(from: Date, daysInPeriod: number): (event: AnimalEvent) => AnimalEvent {
	return (event: AnimalEvent) => {
		const daysSincePeriodStart = differenceInCalendarDays(event.dateOfOnFarmEvent!, from);
		event.setPeriodOffset(Math.abs((daysSincePeriodStart / daysInPeriod) * 100));
		return event;
	};
}
