import { AnimalEventRepresentation, toAnimalEvent } from './AnimalEventRepresentation';
import { LinkedRepresentation } from './Representation';

export interface ManagementNumberEventRepresentation extends AnimalEventRepresentation {
	managementNumber: number;
	isStart: boolean;
}

export function toManagementNumberEvents(
	linkedRepresentations: Array<LinkedRepresentation>
): Array<ManagementNumberEventRepresentation> {
	return linkedRepresentations.map(toInManagementNumberEvent);
}

export function toInManagementNumberEvent(representation: LinkedRepresentation): ManagementNumberEventRepresentation {
	const animalEvent = toAnimalEvent<ManagementNumberEventRepresentation>(
		representation,
		'managementIdentificationEvent'
	);
	animalEvent.managementNumber = representation.managementNumber;
	animalEvent.isStart = representation.isStart;
	return animalEvent;
}
