import { addYears, endOfMonth, startOfMonth, subYears } from 'date-fns';
import { action, observable } from 'mobx';
import { TranslateFunction } from '../i18n';
import { Clock, RealtimeClock } from '../Clock';

export interface DateRange {
	from: Date;
	to: Date;
}

export class UiStore {
	@observable timelineVisible = false;
	@observable timelineDateRange: DateRange;

	constructor(public translate: TranslateFunction, private clock: Clock = new RealtimeClock()) {
		this.timelineDateRange = calculateDateRange(this.clock.now());
	}

	@action toggleTimelineVisible(): void {
		this.timelineVisible = !this.timelineVisible;
	}

	@action resetTimelineVisible(): void {
		this.timelineVisible = false;
	}

	get timelineEventsDateRange(): DateRange {
		return this.timelineDateRange;
	}

	@action setPreviousTimelineEventsDateRange(): void {
		const previousYear = subYears(this.timelineDateRange.to, 1);
		this.timelineDateRange = calculateDateRange(previousYear);
	}

	@action setNextTimelineEventsDateRange(): void {
		if (this.timelineDateRange.to.getFullYear() !== this.clock.now().getFullYear()) {
			const nextYear = addYears(this.timelineDateRange.to, 1);
			this.timelineDateRange = calculateDateRange(nextYear);
		}
	}

	@action setDefaultTimelineEventsDateRange(): void {
		this.timelineDateRange = calculateDateRange(this.clock.now());
	}
}

function calculateDateRange(asAt: Date): DateRange {
	return {
		from: startOfMonth(subYears(asAt, 1)),
		to: endOfMonth(asAt),
	};
}
