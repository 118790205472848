import React, { FunctionComponent } from 'react';
import { addMonths, differenceInCalendarMonths } from 'date-fns';
import { MonthAndYear } from '../../../../../../../../services/formatters/formatters';
import { NextPreviousVisiblePeriod } from './components/NextPreviousVisiblePeriod/NextPreviousVisiblePeriod';
import styles from './VisiblePeriodControl.module.scss';

interface VisiblePeriodControlProps {
	from: Date;
	to: Date;
}

export const VisiblePeriodControl: FunctionComponent<VisiblePeriodControlProps> = ({ from, to }) => {
	const months: Array<string> = [];
	const differenceInMonths = differenceInCalendarMonths(to, from);
	let startDate = from;

	for (let i = 0; i < differenceInMonths + 1; i++) {
		months.push(MonthAndYear(startDate));
		startDate = addMonths(startDate, 1);
	}

	return (
		<div className={styles.main}>
			<NextPreviousVisiblePeriod />
			{months.map(monthAndYear => (
				<div key={monthAndYear}>{monthAndYear}</div>
			))}
		</div>
	);
};
