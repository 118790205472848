import { decorate, observable } from 'mobx';

export function decorateAllAsObservable<T>(obj: T): T {
	const decorators = {};
	Object.keys(obj)
		.filter(key => typeof obj[key] !== 'function')
		.forEach(key => (decorators[key] = observable));

	return decorate(obj, decorators);
}
