export enum LinkRelationName {
	AgriculturalOperations = 'agricultural-operations',
	Animals = 'animals',
	CalvingEvents = 'calving-events',
	ElectronicIdEvents = 'electronic-id-events',
	EmbryoImplantEvents = 'embryo-implant-events',
	Group = 'group',
	GroupMembershipEvents = 'group-membership-events',
	HealthConditionEvents = 'health-condition-events',
	HeatEvents = 'heat-events',
	HerdEvents = 'herd-events',
	Herds = 'herds',
	InMilkEvents = 'in-milk-events',
	LactationEvents = 'lactation-events',
	LiveWeightEvents = 'live-weight-events',
	ManagementIdEvents = 'management-id-events',
	MatingEvents = 'mating-events',
	PregnancyDiagnoses = 'pregnancy-diagnoses',
	Self = 'self',
}
